<template>
  <div></div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PostLogin',
  async mounted() {
    await this.login();
    await this.getLicenseData();
    const redirect_from = localStorage.getItem('redirect_from');

    if (!this.licenseData.user_is_current) {
      this.$router.push('/terms/');
    }
    else if (redirect_from) {
      await this.$router.push(redirect_from);
      localStorage.removeItem('redirect_from');
    } else {
      this.$router.push('/tenants/');
    }
  },
  methods: {
    ...mapActions(['login','getLicenseData']),
  },
  computed: {
    ...mapGetters(['licenseData']),
  },
};
</script>

<style>
</style>